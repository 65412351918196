import React from 'react';
import { useTranslation } from 'react-i18next';

// @material-ui
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: '4px',
    padding: theme.spacing(1),
    overflow: 'hidden',
    backgroundColor: theme.palette.error.main,
    marginBottom: theme.spacing(3),
    textAlign: 'center',
    color: '#FFFFFF',
    [theme.breakpoints.down('sm')]: {
      top: '70px',
    },
  },
}));

const TempNotice = ({ message }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Typography>{message}</Typography>
    </Box>
  );
};

export default TempNotice;
