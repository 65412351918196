import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Redux
import { useSelector, useDispatch } from 'react-redux';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Box,
  MenuItem,
  InputLabel,
  FormControl,
  Select,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';

// components
import { ALERT_FAIL } from 'redux/actions/types';
import { updateCustomer } from 'redux/actions/customer';
import { getCharities } from 'redux/actions/charities';

const useStyles = makeStyles((theme) => ({
  button: {
    cursor: 'pointer',
    color: theme.palette.aqua.main,
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  content: {
    [theme.breakpoints.up(400)]: {
      minWidth: 350,
    },
    color: 'blue',
  },
  label: {
    color: theme.palette.primary.main,
  },
  link: {
    position: 'absolute',
    left: '10px',
    color: theme.palette.aqua.main,
    textDecoration: 'none',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.main,
      cursor: 'pointer',
    },
  },
}));

function PaymentPreferenceForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const paymentData = useSelector((state) => state.transactions.paymentData);
  const customer = useSelector((state) => state.auth.customer);
  const charities = useSelector((state) => state.charities.list);
  const [selectedPayment, setSelectedPayment] = useState('Select');
  const [selectedCharity, setSelectedCharity] = useState({
    business_name: 'Select',
  });
  const [viewDonate, setViewDonate] = useState(false);

  const handleClose = () => {
    props.handleClose('payment');
    setViewDonate(false);
  };

  const handleSubmit = () => {
    const paymentPreference = {
      preferred_payment_option: paymentData.keep.id,
      preferred_cashout_option: paymentData.keep.methods[selectedPayment]?.id,
    };
    const donationPreference = {
      preferred_payment_option: paymentData.donate.id,
      preferred_donee_id: selectedCharity?.id,
    };

    dispatch(
      updateCustomer(!viewDonate ? paymentPreference : donationPreference),
    );
    handleClose();
  };

  const handlePaymentSelect = (event) => {
    const name = event.target.value;
    const noEcoCardSetup = name === 'card' && !customer.onbe_account_number;

    if (noEcoCardSetup) {
      dispatch({
        type: ALERT_FAIL,
        payload: t('account.form.setupEcoCardFirst'),
      });

      return;
    }

    !viewDonate && setSelectedPayment(name);
    viewDonate && setSelectedCharity(name);
  };

  const handleViewDonate = () => {
    charities.length <= 0 && dispatch(getCharities());
    setViewDonate((current) => !current);
  };

  return (
    <>
      <Dialog
        open={props.open}
        onClose={props.handleClose}
        PaperProps={{
          style: { borderRadius: '10px' },
        }}
      >
        <DialogTitle id="form-dialog-title" color="primary">
          {!viewDonate
            ? t('account.form.selectPreferredPayment')
            : t('account.form.selectPreferredCharity')}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <FormControl>
            <InputLabel
              className={classes.label}
              id="select-payment-preference"
            >
              {t('account.form.preferenceLabel')}
            </InputLabel>
            {!viewDonate && (
              <Select
                id="select-payment-preference"
                value={selectedPayment}
                disableUnderline
                renderValue={() => {
                  return selectedPayment;
                }}
                onChange={handlePaymentSelect}
              >
                {paymentData.keep?.methods &&
                  Object.keys(paymentData.keep?.methods).map(
                    (payment, index) => (
                      <MenuItem value={payment} key={index}>
                        {paymentData.keep.methods[payment].name}
                      </MenuItem>
                    ),
                  )}
              </Select>
            )}
            {viewDonate && (
              <Select
                id="select-payment-preference"
                value={selectedCharity?.business_name}
                disableUnderline
                renderValue={() => {
                  return selectedCharity?.business_name;
                }}
                onChange={handlePaymentSelect}
              >
                {charities.map((charity, index) => (
                  <MenuItem value={charity} key={index}>
                    {charity.business_name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions>
          {!viewDonate && (
            <Box className={classes.link} onClick={handleViewDonate}>
              <Typography variant="body2">
                {t('account.form.donateInstead')}
              </Typography>
            </Box>
          )}
          <Button className={classes.button} onClick={handleClose}>
            {t('buttons.cancel')}
          </Button>
          <Button
            className={classes.button}
            disabled={
              !viewDonate
                ? selectedPayment == 'Select'
                : selectedCharity.business_name == 'Select'
            }
            onClick={handleSubmit}
          >
            {t('buttons.submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default PaymentPreferenceForm;
