import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import ReactCodeInput from 'react-verification-code-input';

// Redux
import { useDispatch } from 'react-redux';
import {
  verificationLoggedIn,
  phoneVerification,
} from 'redux/actions/verification';
import { updateCustomer } from 'redux/actions/customer';

// Form Validation
import { useFormik } from 'formik';
import * as yup from 'yup';

// Material UI
import { makeStyles } from '@material-ui/core/styles';
import { Fade } from '@material-ui/core';
import { Box, Typography, Divider, Card } from '@material-ui/core';
// @material-ui/icons
import Edit from '@material-ui/icons/Edit';

import PortalInputLabel from 'components/TextField/PortalInputLabel';
import PortalTextFieldNoShadow from 'components/TextField/PortalTextFieldNoShadow';

// styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    // height: '100%',
    borderRadius: 10,
    boxShadow: theme.shadows[25],
    padding: theme.spacing(2, 2, 3),
  },
  form: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
  },
  content: {
    flex: '1',
  },
  input: {
    marginBottom: theme.spacing(1),
    transition: 'all .3s ease-out',
    paddingLeft: '7px',
    width: 'auto',
    '&..MuiSelect-icon': {
      display: 'none',
    },
    '&.Mui-disabled': {
      color: theme.palette.text.primary,
      marginBottom: 0,
      '&&&:before': {
        borderBottom: 'none',
      },
      '&&:after': {
        borderBottom: 'none',
      },
    },
  },
  sectionText: {
    paddingLeft: '7px',
  },
  title: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 400,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(1),
  },
  inputLabel: {
    fontSize: theme.typography.pxToRem(12),
    marginBottom: '5px',
  },
  button: {
    cursor: 'pointer',
    display: 'inline-flex',
    border: 'none',
    color: theme.palette.aqua.main,
    backgroundColor: 'transparent',
    padding: 0,
    marginRight: theme.spacing(2),
    fontSize: theme.typography.pxToRem(14),
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  label: {
    marginBottom: '2px',
    color: theme.palette.primary.main,
    fontSize: theme.typography.pxToRem(12),
  },
  phoneNumber: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.pxToRem(18),
    fontWeight: 400,
    border: 'none',
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  numberInput: {
    marginTop: theme.spacing(2),
    minWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
    '& input': {
      boxShadow: '0px 0px 10px 0px rgba(0,0,0,.10)',
      '&:focus': {
        borderColor: theme.palette.tertiary.main,
        caretColor: theme.palette.tertiary.main,
      },
    },
  },
}));

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  const classes = useStyles();
  return (
    <NumberFormat
      className={classes.phoneNumber}
      {...other}
      format="+1 (###) ###-####"
      allowEmptyFormatting
      mask="_"
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default function PhoneNumberForm(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [phoneNumberUsed, setPhoneNumberUsed] = useState('');
  const [showVerification, setShowVerification] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [edit, setEdit] = useState(true);

  const handleEdit = () => {
    setEdit(false);
  };

  const handleCancel = () => {
    setEdit(true);
    setShowVerification(false);
  };

  const { phoneNumber } = props.validation;

  const validationSchema = yup.object({
    phoneNumber,
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      phone_number: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      dispatch(verificationLoggedIn(values)).then(() => {
        setShowVerification(true);
        setPhoneNumberUsed(values.phone_number);
      });
    },
  });

  const handlePhoneVerified = () => {
    dispatch(updateCustomer({ phone_number: phoneNumberUsed }));
    setPhoneNumberUsed('');
    setVerificationCode('');
    setShowVerification('');
    setEdit(true);
  };

  const handleSubmitCode = () => {
    dispatch(phoneVerification(verificationCode)).then(() => {
      handlePhoneVerified();
    });
  };

  const formatPhoneNumber = (input) => {
    input = input.replace(/\D/g, '').substring(0, 10); //Strip everything but 1st 10 digits
    var size = input.length;
    if (size > 0) {
      input = '(' + input;
    }
    if (size > 3) {
      input = input.slice(0, 4) + ') ' + input.slice(4);
    }
    if (size > 6) {
      input = input.slice(0, 9) + '-' + input.slice(9);
    }
    return input;
  };

  return (
    <React.Fragment>
      <Card className={classes.root}>
        <Typography className={classes.title}>
          {t('account.form.phoneNumber')}
        </Typography>
        <form className={classes.form} onSubmit={formik.handleSubmit}>
          <Box className={classes.content}>
            {edit && (
              <Fade in={true}>
                <div className={classes.sectionText}>
                  <Typography>
                    {props.customer.phone_number
                      ? formatPhoneNumber(props.customer.phone_number)
                      : 'Add your phone number'}
                  </Typography>
                </div>
              </Fade>
            )}
            {!edit && (
              <div>
                {!showVerification && (
                  <Box mb={2}>
                    <PortalInputLabel
                      classes={{ root: classes.label }}
                      id="phoneNumber"
                    >
                      {t('account.form.newNumber')}
                    </PortalInputLabel>
                    <PortalTextFieldNoShadow
                      id="phoneNumber"
                      name="phone_number"
                      value={formik.phone_number}
                      onChange={formik.handleChange}
                      inputComponent={NumberFormatCustom}
                      error={
                        formik.touched.phone_number &&
                        Boolean(formik.errors.phone_number)
                      }
                      helperText={
                        formik.touched.phone_number &&
                        formik.errors.phone_number
                      }
                    />
                  </Box>
                )}
                {showVerification && (
                  <>
                    <Typography>
                      {t('account.form.phoneNumberCodeSent', {
                        location: formatPhoneNumber(phoneNumberUsed),
                      })}
                    </Typography>
                    <ReactCodeInput
                      id="verification_code"
                      name="email verification code"
                      type="number"
                      fields={4}
                      autoFocus={false}
                      className={classes.numberInput}
                      autoComplete="one-time-code"
                      onChange={(e) => setVerificationCode(e)}
                    />
                  </>
                )}
              </div>
            )}
          </Box>
          <Box className={classes.buttons}>
            <Box py={2}>
              <Divider />
            </Box>
            <Box display="flex" justifyContent="flex-end">
              {edit && (
                <Box display="flex" alignItems="center">
                  <button className={classes.button} onClick={handleEdit}>
                    <Edit className={classes.icon} fontSize="small" />
                    {t('account.form.updatePhoneNumber')}
                  </button>
                </Box>
              )}
              {!edit && (
                <div>
                  <button className={classes.button} onClick={handleCancel}>
                    {t('buttons.cancel')}
                  </button>
                  {!showVerification && (
                    <button className={classes.button} type="submit">
                      {t('buttons.submit')}
                    </button>
                  )}
                  {showVerification && (
                    <button
                      className={classes.button}
                      onClick={handleSubmitCode}
                    >
                      {t('buttons.submit')}
                    </button>
                  )}
                </div>
              )}
            </Box>
          </Box>
        </form>
      </Card>
    </React.Fragment>
  );
}

PhoneNumberForm.propTypes = {
  handleChange: PropTypes.func,
};
