/* eslint-disable */

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams, useLocation } from 'react-router-dom';

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setLanguage } from 'redux/actions/auth';

// @material-ui/core components
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Fade, Badge } from '@material-ui/core';

// components
import MemberPortalPage from 'components/Page/MemberPortalPage';
import DashTab from 'components/DashboardTab/DashTab';
import DashTabs from 'components/DashboardTab/DashTabs';
import ProgressSpinner from 'components/Progress/ProgressSpinner';
import GeoLocator from 'components/GeoLocator/GeoLocator';
import Header from './Routes/Header';
import Content from './Routes/Content';
// import DwollaSignup from './EcoWallet/Sections/DwollaSignup';

// assets
// import scheduleIcon from 'assets/img/portal/portalIcon_Schedule.svg';
import dashboardIcon from 'assets/img/portal/portalIcon_Dashboard.svg';
import walletIcon from 'assets/img/portal/portalIcon_Wallet.svg';
import metricsIcon from 'assets/img/portal/portalIcon_Metrics.svg';
import { CUSTOMER_PENDING } from 'redux/actions/types';
import { getCustomerInfo } from 'redux/actions/customer';
import cartIcon from 'assets/img/portal/portalIcon_Cart.svg';


// For Accessability
function allyProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  headerCard: {
    width: '100%',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
    borderBottom: '1px solid #004f71 ',
  },
  name: {
    fontWeight: 400,
  },
  subtitle: {
    fontSize: '1.125rem',
    color: theme.palette.tertiary.main,
  },
  padding: {
    padding: theme.spacing(3),
  },
  panel: {
    height: '100%',
    backgroundColor: theme.palette.background.portal,
  },
  tabName: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: '15px',
    top: '0',
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
    color: theme.palette.primary.main,
  },
}))(Badge);

export default function MemberPortal(props) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let { page } = useParams();
  // page = 'transfer_signup';
  const customer = useSelector((state) => state.auth.customer);
  const { totalItems } = useSelector((state) => state.cart);
  const [selectedLink, setSelectedLink] = useState(
    location.state?.referrer || '',
  );

  const routes = {
    dashboard: '/portal/dashboard',
    // schedule: '/portal/schedule',
    metrics: '/portal/metrics',
    ecowallet: '/portal/ecowallet',
    // ecosupplies: '/portal/ecosupplies',
  };

  const nav = [
    {
      name: 'Dashboard',
      text: 'nav.dashboard',
      route: routes.dashboard,
      icon: dashboardIcon,
    },
    /* pickups suspended as of May 2024
    {
      name: 'Scheduler',
      text: 'nav.scheduler',
      route: routes.schedule,
      icon: scheduleIcon,
    },*/
    {
      name: 'Metrics',
      text: 'nav.metrics',
      route: routes.metrics,
      icon: metricsIcon,
    },
    {
      name: 'EcoWallet',
      text: 'nav.ecowallet',
      route: routes.ecowallet,
      icon: walletIcon,
    },
    // {
    //   name: 'EcoSupplies',
    //   text: 'nav.ecosupplies',
    //   route: routes.ecosupplies,
    //   icon: cartIcon,
    // },
  ];

  const handleChange = (route) => {
    history.push(route);
    setSelectedLink(route);
  };

  React.useEffect(() => {
    dispatch({ type: CUSTOMER_PENDING, payload: true });
    dispatch(getCustomerInfo());
    customer.preferred_language && setPreferredLanguage();
  }, []);

  const setPreferredLanguage = () => {
    i18n.changeLanguage(customer.preferred_language);
    dispatch(setLanguage(customer.preferred_language));
  };

  if (customer.pending) {
    return <ProgressSpinner text={'Loading...'} />;
  } else {
    return (
      <MemberPortalPage>
        <GeoLocator />
        <div>
          <div className={classes.headerCard}>
            <Header />
          </div>
          <div>
            <DashTabs
              value={location.pathname.includes(selectedLink) && selectedLink}
              aria-label="Member Portal Tabs"
            >
              {nav.map((link, index) => (
                <DashTab
                  key={index}
                  value={link.route}
                  label={
                    <span className={classes.tabName}>{t(link.text)}</span>
                  }
                  icon={
                    link.name === 'EcoSupplies' ? (
                      <StyledBadge
                        className={classes.badge}
                        color="secondary"
                        badgeContent={totalItems}
                      >
                        <img
                          style={{ height: 'auto', width: '30px' }}
                          src={link.icon}
                        />
                      </StyledBadge>
                    ) : (
                      <img
                        style={{ height: 'auto', width: '30px' }}
                        src={link.icon}
                      />
                    )
                  }
                  onClick={() => handleChange(link.route)}
                  {...allyProps(0)}
                />
              ))}
            </DashTabs>
          </div>
          <div>
            <Content />
          </div>
        </div>
      </MemberPortalPage>
    );
  }
}
